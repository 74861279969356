import clsx from 'clsx';
import React from 'react';
import { Page } from './Page';

function renderContactText() {
  return (
    <div className={clsx('section-intro', 'center-block')}>
      Do you want us to play at your pub, brighten up a reception or even your wedding? Have you got any questions, or do you just want to
      know what conditioner Tom uses to get his hairdo so nice and fluffy? Do not hesitate to send us an email.
    </div>
  );
}

function renderContactEmails() {
  return (
    <div className={clsx('contact-block', 'mr-auto', 'ml-auto')}>
      <div className="row">{renderEmail('Contact', 'fa-info-circle')}</div>
    </div>
  );
}

function renderEmail(title: string, icon: string) {
  const name = 'reinout';
  const mailAdress = `${name}@reinoutstevens.be`;
  return (
    <div className={clsx('item', 'col-12', 'col-lg-12')}>
      <div className="item-inner">
        <div className="icon-holder">
          <i className={clsx('fa', icon)} />
        </div>
        <h4 className="title">{title}</h4>
        <div className="email">
          <a href={`mailto:${mailAdress}`}>{mailAdress}</a>
        </div>
      </div>
    </div>
  );
}

function renderSocialMedium(link: string, icon: string) {
  return (
    <li className="list-inline-item">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <i className={clsx('fa', icon)} />
      </a>
    </li>
  );
}

function renderSocialMedia() {
  return (
    <div className="social-media-block">
      <ul className={clsx('list-inline', 'social-media-list')}>
        {renderSocialMedium('https://www.facebook.com/JUST-smallband-1997161883934198', 'fa-facebook')}
        {renderSocialMedium('https://soundcloud.com/just-smallband', 'fa-soundcloud')}
      </ul>
    </div>
  );
}

export const Contact: React.FC = () => {
  return (
    <Page id="contact" title="Contact">
      {renderContactText()}
      {renderContactEmails()}
      {renderSocialMedia()}
    </Page>
  );
};

export default Contact;
