import clsx from 'clsx';
import React from 'react';

export interface PageProps {
  id: string;
  title?: React.ReactNode;
}

export const Page: React.FC<PageProps> = ({ id, title, children }) => {
  return (
    <section id={id} className={clsx(`${id}-section`, 'section', 'text-center')}>
      <div className="container">
        {title && <h2 className="section-title">{title}</h2>}
        {children}
      </div>
    </section>
  );
};

export default Page;
