import clsx from 'clsx';
import React from 'react';
import { ScrollLink } from '../lib/ScrollLink';

export interface HeaderState {
  scrolled: boolean;
  showCollapsedMenu: boolean;
}

export class Header extends React.Component<{}, HeaderState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      scrolled: false,
      showCollapsedMenu: false,
    };
  }

  public render() {
    const { showCollapsedMenu, scrolled } = this.state;
    const headerStyle = scrolled ? 'header-scrolled' : 'header';
    const menuClass = showCollapsedMenu ? 'show' : '';
    return (
      <header id="header" className={headerStyle} style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1 }}>
        <div className="container">
          <nav className={clsx('main-nav', 'navbar', 'navbar-expand-md')} role="navigation">
            <button
              className={clsx('navbar-toggler', 'navbar-dark')}
              type="button"
              aria-controls="navbar-collapse"
              aria-expanded={showCollapsedMenu}
              aria-label="Toggle navigation"
              onClick={(ev) => this.handleMenuClicked()}
            >
              <span className="toggle-title">Menu</span>
              <span className="navbar-toggler-icon" />
            </button>
            <div id="navbar-collapse" className={clsx('navbar-collapse', 'collapse', 'text-center', 'justify-content-center', menuClass)}>
              <ul className={clsx('nav', 'navbar-nav')}>
                <ScrollLink id="promo">Home</ScrollLink>
                <ScrollLink id="gigs">Gigs</ScrollLink>
                <ScrollLink id="music">Music</ScrollLink>
                <ScrollLink id="about">About</ScrollLink>
                <ScrollLink id="contact">Contact</ScrollLink>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    );
  }

  public componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  public componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  private handleScroll = (event: Event) => {
    const height = window.scrollY;
    this.setState({
      scrolled: height > 0,
    });
  };

  private handleMenuClicked() {
    this.setState({
      showCollapsedMenu: !this.state.showCollapsedMenu,
    });
  }
}
