import React from 'react';
import ServicesContext from './ServicesContext';
import withFirebase, { WithFirebase } from '../Session/withFirebase';
import ConcertServices from './ConcertServices';

export type ServicesContextProviderProps = WithFirebase;

class ServicesContextProvider extends React.Component<ServicesContextProviderProps> {
  private concert: ConcertServices;

  constructor(props: ServicesContextProviderProps) {
    super(props);
    this.concert = new ConcertServices(props.firebase);
  }
  render() {
    const { children } = this.props;
    return (
      <ServicesContext.Provider
        value={{
          concert: this.concert,
        }}
      >
        {children}
      </ServicesContext.Provider>
    );
  }
}
export default withFirebase(ServicesContextProvider);
