import { initializeApp } from 'firebase/app';
import { Auth, getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, User } from 'firebase/auth';
import { Firestore, getFirestore, doc, getDoc } from 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyArqY3TfWOzkslkImPNJplBYa_cM8JUz7c',
  authDomain: 'just-smallband.firebaseapp.com',
  databaseURL: 'https://just-smallband.firebaseio.com',
  messagingSenderId: '445768006227',
  projectId: 'just-smallband',
  storageBucket: 'just-smallband.appspot.com',
};

const firebaseApp = initializeApp(config);

type AuthChangeListener = (user: User | null) => void;

export interface UserData {
  name: string | null;
  role: 'admin' | 'user';
}

class Firebase {
  public readonly db: Firestore;
  private auth: Auth;
  private user: User | null;

  private userData: UserData | null;

  private onAuthChangeListeners: AuthChangeListener[];

  constructor() {
    this.userData = null;
    this.auth = getAuth(firebaseApp);
    this.db = getFirestore(firebaseApp);
    this.user = null;
    this.onAuthChangeListeners = [];
    this.user = this.auth.currentUser;
    onAuthStateChanged(this.auth, async (user) => {
      this.user = user;
      if (this.user) {
        const userDocRef = doc(this.db, 'users', this.user.uid);
        const userDoc = await getDoc(userDocRef);
        if (!userDoc.exists) {
          this.userData = {
            name: this.user.displayName,
            role: 'user',
          };
        } else {
          this.userData = userDoc.data() as UserData;
        }
      }
      this.onAuthChangeListeners.forEach((cb) => cb(user));
    });
  }

  public currentUser(): User | null {
    return this.user;
  }

  public isLoggedIn(): boolean {
    return !!this.user;
  }

  public isAdmin(): boolean {
    return !!(this.userData && this.userData.role === 'admin');
  }

  public addAuthChangeListener(cb: AuthChangeListener) {
    this.onAuthChangeListeners.push(cb);
  }

  public removeAuthChangeListener(cb: AuthChangeListener) {
    this.onAuthChangeListeners = this.onAuthChangeListeners.filter((listener) => listener !== cb);
  }

  public async signIn(): Promise<void> {
    const provider = new GoogleAuthProvider();
    provider.addScope('email');
    await signInWithPopup(this.auth, provider);
  }

  public signOut(): Promise<void> {
    return this.auth.signOut();
  }
}

export default Firebase;
