import React from 'react';
import { Route, Routes } from 'react-router-dom';
import OnePageApp from './Components/OnePageApp';
import ScaleLoader from 'react-spinners/ScaleLoader';

const AdminRoutes = React.lazy(() => import('./Admin/AdminRoutes'));

class App extends React.Component {
  public render() {
    return (
      <div>
        <React.Suspense fallback={<ScaleLoader />}>
          <Routes>
            <Route path="/" element={<OnePageApp />} />
            <Route path="admin/*" element={<AdminRoutes />} />
          </Routes>
        </React.Suspense>
      </div>
    );
  }
}

export default App;
