import React from 'react';
import { makeStyles, Grid, withStyles, Tabs, Tab, Theme, createStyles, Typography } from '@material-ui/core';
import UpcomingConcertsLoader from './UpcomingConcertsLoader';
import PastConcertsLoader from './PastConcertsLoader';
import { scrollTo as _scrollTo } from '../lib/ScrollLink';
import clsx from 'clsx';

enum ConcertTab {
  UPCOMING,
  PAST,
}

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#5bb66f',
    },
  },
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'uppercase',
      color: '#000',
      fontWeight: theme.typography.fontWeightRegular as any,
      fontSize: theme.typography.h6.fontSize,
      marginRight: theme.spacing(1),
      '&:focus': {
        opacity: 1,
        outline: 'none',
      },
    },
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
}));

const ConcertsTabs: React.FC = () => {
  const [selectedTab, setTab] = React.useState<ConcertTab>(ConcertTab.UPCOMING);
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{}>, newValue: ConcertTab) => {
    setTab(newValue);
  };

  const tabs = (
    <Grid container justify="center">
      <Grid item>
        <StyledTabs value={selectedTab} onChange={handleChange}>
          <StyledTab label="Upcoming Gigs"></StyledTab>
          <StyledTab label="Past Gigs"></StyledTab>
        </StyledTabs>
      </Grid>
    </Grid>
  );

  const concerts = selectedTab === ConcertTab.UPCOMING ? <UpcomingConcertsLoader /> : <PastConcertsLoader />;

  return (
    <section id="gigs" className={clsx(`gigs-section`, 'section', 'text-center')}>
      <div className={'container'}>
        {tabs}
        <Typography className={classes.padding} />

        {concerts}
        <Typography className={classes.padding} />

        <div className="gigs-contact text-center">
          <h4 className="title">Want to book us for a show?</h4>
          <a className="btn btn-ghost-primary" href="#contact" onClick={(ev) => _scrollTo('contact')}>
            Get in touch now
          </a>
        </div>
      </div>
    </section>
  );
};

export default ConcertsTabs;
