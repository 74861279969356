import clsx from 'clsx';
import React from 'react';
import { Page } from './Page';
import { makeStyles } from '@material-ui/core';

const soundCloud =
  'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/446537358&color=0E0E10&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true';

const humptyDumpty = 'https://drive.google.com/file/d/14zmCxnHpfvQCLI3z3Oz9HUspzyQ4xeN6/preview';

const useStyles = makeStyles({
  videoContainer: {
    position: 'relative',
    paddingBottom: '56.25%' /* 16:9 */,
    height: 0,
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
});

export const Music: React.FC = () => {
  const classes = useStyles();
  return (
    <Page id="music" title="Music">
      <div className={clsx('sc-block', 'ml-auto', 'mr-auto')}>
        <div className="item">
          <iframe width="100%" height="300" title="SoundCloud" src={soundCloud} />
        </div>
        <div className="item">
          <div className={classes.videoContainer}>
            <iframe title="Studio Dada" src={humptyDumpty} width="768" height="432" />
          </div>
        </div>
      </div>
    </Page>
  );
};
