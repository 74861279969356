import { User } from 'firebase/auth';
import React from 'react';
import Firebase from './Firebase';

export interface FirebaseContextProps {
  firebase: Firebase | null;
  currentUser: User | null;
}

const FirebaseContext = React.createContext<FirebaseContextProps>({
  currentUser: null,
  firebase: null,
});

export default FirebaseContext;
