import clsx from 'clsx';
import React from 'react';
import { Member } from './Member';
import { Page } from './Page';

function renderMembers() {
  return (
    <div className="members-block">
      <div className="row">
        {renderMember('assets/images/members/tom.png', 'Tom', 'Piano', 'Melodica player when he feels like it')}
        {renderMember('assets/images/members/sofie.png', 'Sofie', 'Alt Sax', 'Also known as Sofire when improvising')}
        <div className={clsx('clearfix', 'visible-xs')} />
        {renderMember('assets/images/members/nele.png', 'Nele', 'Tenor Sax', 'Blows your socks off')}
        {renderMember(
          'assets/images/members/janne.png',
          'Janne',
          'Baritone Sax',
          'Keeps our bass player in check by actually having a sense of rhythm',
        )}
        <div className={clsx('clearfix', 'visible-xs')} />
        {renderMember('assets/images/members/johan.png', 'Johan', 'Trumpet', 'Smooth and mellow tones from the heart')}
        {renderMember('assets/images/members/marc.png', 'Marc', 'Trombone', 'A disarming smile with a killer sound')}
        <div className={clsx('clearfix', 'visible-xs')} />
        {renderMember('assets/images/members/chris.png', 'Chris', 'Guitar', 'Will one of these days find the way to the rehearsal room')}
        {renderMember('assets/images/members/jonas.png', 'Jonas', 'Drums', 'Still sad that our bandname is not Sorry about the Drummer')}
        <div className={clsx('clearfix', 'visible-xs')} />
        {renderMember('assets/images/members/reinout.png', 'Reinout', 'Bass Guitar', 'Pink strings are all the rage')}
        <div className={clsx('clearfix', 'visible-xs')} />
      </div>
    </div>
  );
}

function renderMember(picture: string, name: string, role: string, description: string) {
  return (
    <div className={clsx('item', 'col', 'col-lg-4', 'col-xs-6')}>
      <div className="item-inner">
        <Member name={name} role={role} source={picture} description={description} />
      </div>
    </div>
  );
}

function renderIntro() {
  return (
    <div className={clsx('section-intro', 'ml-auto', 'mr-auto')}>
      JUST is a smallband from Schaarbeek, Brussels. The small in smallband is when comparing it to a bigband. We are 9 players instead of
      17. JUST plays jazz from older and newer times.
    </div>
  );
}

export const About: React.FC = () => {
  return (
    <Page id="about" title="About">
      {renderIntro()}
      {renderMembers()}
    </Page>
  );
};

export default About;
