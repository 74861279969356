import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

export const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className={clsx('footer-content', 'text-center')}>
          <Typography>Just Smallband is subsidized by the Flemish Government</Typography>
          <img src="/assets/images/vlaanderen.jpg" alt="gesubsidieerd door Vlaanderen" />
        </div>
      </div>
    </footer>
  );
};
