import { User } from 'firebase/auth';
import React from 'react';
import Firebase from './Firebase';
import FirebaseContext from './FirebaseContext';

export interface WithFirebase {
  firebase: Firebase;
  currentUser: User | null;
}

function withFirebase<T extends WithFirebase = WithFirebase>(
  Component: React.ComponentType<T>,
): React.ComponentType<Omit<T, keyof WithFirebase>> {
  return class extends React.Component<Omit<T, keyof WithFirebase>> {
    render() {
      return (
        <FirebaseContext.Consumer>
          {({ firebase, currentUser }) => {
            return <Component {...(this.props as T)} firebase={firebase} currentUser={currentUser} />;
          }}
        </FirebaseContext.Consumer>
      );
    }
  };
}

export default withFirebase;
