/* eslint-disable jsx-a11y/heading-has-content */
import * as React from 'react';
import { Page } from './Page';

export const Home: React.FC = () => {
  return (
    <Page id="promo">
      <img alt="Cover" style={{ maxWidth: '100%' }} src="assets/images/cover.jpg" />
      <div className="upper-wrapper">
        <h1 className="logo-holder" />
        <h2 className="headline">JUST SmallBand</h2>
        <div className="tagline">Big Jazz Sound from Brussels</div>
      </div>
    </Page>
  );
};
