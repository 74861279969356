import clsx from 'clsx';
import { DateTime } from 'luxon';
import React from 'react';
import { ConcertInfo } from './ConcertInfo';
import { Concert as _Concert } from '../../Services/ConcertServices';

export interface ConcertProps {
  concert: _Concert;
  showYear?: boolean;
}

function renderDate(date: DateTime, showYear: boolean) {
  const dayFormat = showYear ? 'dd/MM' : 'dd';
  const monthFormat = showYear ? ' yyyy' : 'MMM';
  const month = date.toFormat(monthFormat);
  const day = date.toFormat(dayFormat);
  const cls = clsx({ day: !showYear, 'day-month': showYear });
  return (
    <div className="date-label">
      <div className={cls}>{day}</div>
      <div className="month">{month}</div>
    </div>
  );
}

function renderLocation(location: string) {
  return (
    <li className={clsx('location')}>
      <i className={clsx('fa', 'fa-map-marker')} />
      {location}
    </li>
  );
}

function renderDateDetails(date: DateTime) {
  return (
    <li className={clsx('time')}>
      <i className={clsx('fa', 'fa-clock-o')} />
      {date.toLocal().toFormat('H:mm, DDDD')}
    </li>
  );
}

function renderInfo(concert: _Concert) {
  const { name, location, date } = concert;
  return (
    <div className="gig-info">
      <div className="info-content">
        <h3 className="gig-title">{name}</h3>
        <div className="meta">
          <ul className={clsx('meta-list', 'list-inline')}>
            {location && renderLocation(location)}
            {renderDateDetails(date)}
          </ul>
        </div>
      </div>
      <ConcertInfo>{concert.description}</ConcertInfo>
    </div>
  );
}

const Concert: React.FC<ConcertProps> = ({ concert, showYear = false }) => {
  const { date } = concert;
  return (
    <div className="item">
      {renderDate(date, showYear)}
      {renderInfo(concert)}
    </div>
  );
};

export default Concert;
