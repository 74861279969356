import * as React from 'react';

export interface MemberProps {
  name: string;
  role: string;
  source: string;
  description: string;
}

export const Member: React.FC<MemberProps> = ({ name, role, source, description }) => {
  return (
    <div className="member-profile">
      <img className="img-fluid" src={source} alt={name} />
      <div className="member-label">{role}</div>
      <h3 className="member-name">{name}</h3>
      <div className="member-desc">{description}</div>
    </div>
  );
};
