import React, { useEffect } from 'react';
import ServicesContext from '../../Services/ServicesContext';
import { Concert } from '../../Services/ConcertServices';
import ScaleLoader from 'react-spinners/ScaleLoader';
import Concerts from './Concerts';

const UpcomingConcertsLoader: React.FC = () => {
  const [concerts, setConcerts] = React.useState<Concert[]>();
  const services = React.useContext(ServicesContext);

  useEffect(() => {
    if (!services) {
      setConcerts([]);
    } else {
      services.concert
        .list()
        .then((concerts) => {
          setConcerts(concerts);
        })
        .catch((err) => {
          setConcerts([]);
        });
    }
  }, [services]);

  if (!concerts) {
    return <ScaleLoader />;
  }

  return <Concerts concerts={concerts} />;
};

export default UpcomingConcertsLoader;
