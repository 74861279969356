import { DateTime } from 'luxon';
import 'firebase/firestore';
import 'firebase/auth';
import Firebase from '../Session/Firebase';
import { addDoc, collection, deleteDoc, doc, Firestore, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';

export interface ConcertRecord {
  location: string;
  name: string;
  description: string;
  date: Date;
  id?: string;
}

export interface ConcertRecords {
  concerts: ConcertRecord[];
}

export interface Concert {
  location: string;
  name: string;
  description: string;
  date: DateTime;
  id?: string;
}

class ConcertServices {
  private db: Firestore;

  constructor(firebase: Firebase) {
    this.db = firebase.db;
  }

  public async list(): Promise<Concert[]> {
    const q = query(collection(this.db, 'concerts'), where('date', '>=', DateTime.local().minus({ week: 1 }).toJSDate()));
    const snapshot = await getDocs(q);

    const result: Concert[] = [];
    snapshot.forEach((doc) => {
      const concertRecord: ConcertRecord = doc.data() as ConcertRecord;
      const concert = this.fromRecord(concertRecord, doc.id);
      result.push(concert);
    });
    return result;
  }

  public async pastConcerts(): Promise<Concert[]> {
    const q = query(collection(this.db, 'concerts'), where('date', '<', DateTime.local().minus({ week: 1 }).toJSDate()));
    const snapshot = await getDocs(q);

    const result: Concert[] = [];
    snapshot.forEach((doc) => {
      const concertRecord: ConcertRecord = doc.data() as ConcertRecord;
      const concert = this.fromRecord(concertRecord, doc.id);
      result.push(concert);
    });
    return result;
  }

  public async add(concert: Concert): Promise<string> {
    const docRef = await addDoc(collection(this.db, 'concerts'), this.toRecord(concert));
    return docRef.id;
  }

  public async get(id: string): Promise<Concert> {
    const concertRecord = await getDoc(doc(this.db, 'concerts', id));
    if (!concertRecord.exists) {
      throw new Error('Concert not found');
    }
    const data: ConcertRecord = concertRecord.data() as ConcertRecord;
    return this.fromRecord(data, concertRecord.id);
  }

  public update(concert: Concert): Promise<void> {
    const ref = doc(this.db, 'concerts', concert.id!);
    return updateDoc(ref, this.toRecord(concert) as any);
  }

  public delete(id: string): Promise<void> {
    const ref = doc(this.db, 'concerts', id);
    return deleteDoc(ref);
  }

  private toRecord(concert: Concert): ConcertRecord {
    const record = {
      ...concert,
      date: concert.date.toJSDate(),
    };
    return record;
  }

  private fromRecord(record: ConcertRecord, id: string): Concert {
    const timeStamp = record.date as any;
    const jsDate = timeStamp.toDate();
    const date = DateTime.fromJSDate(jsDate);
    return {
      ...record,
      date: date,
      id: id,
    };
  }
}

export default ConcertServices;
