import clsx from 'clsx';
import React from 'react';
import { Link } from '@material-ui/core';

export interface ConcertInfoProps {
  buttonText?: string;
}

export interface ConcertInfoState {
  open: boolean;
  hovered: boolean;
}

export class ConcertInfo extends React.Component<ConcertInfoProps, ConcertInfoState> {
  constructor(props: ConcertInfoProps) {
    super(props);
    this.state = {
      hovered: false,
      open: false,
    };
  }

  public render() {
    const { open } = this.state;
    const buttonText = this.props.buttonText || 'More info';
    const style: React.CSSProperties = {
      display: open ? 'inline' : 'hidden',
    };
    return (
      <React.Fragment>
        <div className="gig-actions">
          <Link
            component="a"
            className={clsx('btn', 'btn-xs', 'btn-ghost-secondary')}
            onClick={this.toggle}
            aria-expanded={open}
            onMouseEnter={() => {
              this.setState({ hovered: true });
            }}
            onMouseLeave={() => {
              this.setState({ hovered: false });
            }}
            style={{ color: this.state.hovered ? '#fff' : '#000' }}
          >
            {buttonText}
          </Link>
        </div>
        <div className={clsx('collapse', 'info-extra')} style={style}>
          {open && this.props.children}
        </div>
      </React.Fragment>
    );
  }

  private toggle = () => {
    this.setState({ open: !this.state.open });
  };
}
