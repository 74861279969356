import * as React from 'react';
import Concert from './Concert';
import { Concert as _Concert } from '../../Services/ConcertServices';
import { Typography } from '@material-ui/core';

export interface ConcertsProps {
  concerts: _Concert[];
  showYear?: boolean;
}

const Concerts: React.FC<ConcertsProps> = ({ concerts, showYear }) => {
  if (concerts.length === 0) {
    return (
      <>
        <Typography>No upcoming concerts... for now.</Typography>
        <Typography>Check our past concerts instead.</Typography>
      </>
    );
  }

  const sorted = concerts.sort((concertA, concertB) => (concertA.date > concertB.date ? -1 : 1));
  const concertList = sorted.map((concert, i) => {
    return <Concert concert={concert} key={i} showYear={showYear} />;
  });
  return <>{concertList}</>;
};

export default Concerts;
