import React from 'react';
import ConcertServices from './ConcertServices';

export interface ServicesContextProps {
  concert: ConcertServices;
}

const ServicesContext = React.createContext<ServicesContextProps | null>(null);

export default ServicesContext;
