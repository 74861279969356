import clsx from 'clsx';
import React from 'react';

export interface BackToTopState {
  visible: boolean;
}

export class BackToTop extends React.Component<{}, BackToTopState> {
  private scrollTo: number;
  private startLine: number;

  constructor(props: {}) {
    super(props);
    this.scrollTo = 0;
    this.startLine = 100;
    this.state = {
      visible: this.checkVisible(),
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  public render() {
    const { visible } = this.state;
    const style: React.CSSProperties = {
      bottom: '5px',
      cursor: 'pointer',
      display: visible ? 'inline' : 'none',
      opacity: 1,
      position: 'fixed',
      right: '5px',
    };
    return (
      <div
        id="topcontrol"
        title="Scroll Back to Top"
        style={style}
        onClick={(ev) => {
          this.scrollToTop();
        }}
      >
        <i className={clsx('fa', 'fa-angle-up')} />
      </div>
    );
  }

  public componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  public componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  private scrollToTop() {
    window.scrollTo({ top: this.scrollTo, behavior: 'smooth' });
  }

  private handleScroll() {
    this.setState({
      visible: this.checkVisible(),
    });
  }

  private checkVisible(): boolean {
    const height = window.scrollY;
    return height > this.startLine;
  }
}
