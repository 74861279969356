import * as React from 'react';
import { BackToTop } from './lib/BackToTop';
import { About } from './Static/About';
import { Contact } from './Static/Contact';
import { Footer } from './Static/Footer';
import { Header } from './Static/Header';
import { Home } from './Static/Home';
import { Music } from './Static/Music';
import ConcertsTabs from './Concerts/ConcertsTabs';

const OnePageApp: React.FC = () => {
  return (
    <>
      <Header />
      <Home />
      <ConcertsTabs />
      <Music />
      <About />
      <Contact />
      <Footer />
      <BackToTop />
    </>
  );
};

export default OnePageApp;
