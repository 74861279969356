import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'font-awesome/css/font-awesome.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './assets/css/bootstrap.min.css';
import './assets/css/styles.css';
import registerServiceWorker from './registerServiceWorker';
import FirebaseContextProvider from './Session/FirebaseContextProvider';
import ServicesContextProvider from './Services/ServicesContextProvider';

ReactDOM.render(
  <FirebaseContextProvider>
    <ServicesContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ServicesContextProvider>
  </FirebaseContextProvider>,
  document.getElementById('root'),
);
registerServiceWorker();
