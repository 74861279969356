import clsx from 'clsx';
import React from 'react';

export interface ScrollLinkProps {
  id: string;
}

export function scrollTo(elementId: string) {
  const el = document.getElementById(elementId);
  if (el) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
}

function clickHandler(ev: React.MouseEvent<HTMLElement>, id: string) {
  ev.preventDefault();
  scrollTo(id);
}

export const ScrollLink: React.FC<ScrollLinkProps> = ({ id, children }) => {
  return (
    <li className="nav-item">
      <a className={clsx('active', 'nav-link')} href={`#${id}`} onClick={(ev) => clickHandler(ev, id)}>
        {children}
      </a>
    </li>
  );
};
